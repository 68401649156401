import axiosInstance from "../../api/axiosInstance";

const state = {
  authHeaders: null,
  user: {
    id: null,
    username: null,
    email: null,
  },
};
const getters = {
  notCancelledSubscribed: (state) => {
    return state.user.role_type && (state.user.subscribed || !state.user.cancelled_status);
  },
  subscriptionNavigationHelper: (state, getters) => {
    if (getters.notCancelledSubscribed) {
      const navigation =
        state.user.role_type == "professional"
          ? "editProfessionalPlan"
          : "editGuardianPlan";
      return navigation;
    } else if (state.user.plan_preferences.role) {
      const navigation =
        state.user.plan_preferences.role == "professional"
          ? "professionalPlan"
          : "guardianPlan";
      return navigation;
    } else {
      return "selectRole";
    }
  },
  user(state) {
    return state.user;
  },
  getAuthHeaders(state) {
    return state.authToken;
  },
  getUserEmail(state) {
    return state.user?.email;
  },
  getUserID(state) {
    return state.user?.id;
  },
  isLoggedIn(state) {
    const loggedOut =
      state.authHeaders == null || state.authHeaders == JSON.stringify(null);
    return !loggedOut;
  },
};
const actions = {
  registerUser({ commit }, payload) {
    return axiosInstance
      .post("/sessions", payload)
      .then((response) => {
        commit("setUserInfo", response.data);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  loginUser({ commit }, payload) {
    return axiosInstance
      .post("/sessions/sign_in", payload)
      .then((response) => {
        commit("setUserInfo", response.data);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  logoutUser({ commit }) {
    return axiosInstance
      .delete("/sessions/sign_out")
      .then(() => {
        commit("resetUserInfo");
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  loginUserWithToken({ commit }) {
    return axiosInstance
      .get("/sessions/validate_token")
      .then((response) => {
        commit("setUserInfo", response.data);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  fetchCleverRedirect({ }, state) {
    return axiosInstance
      .get("/clever_auth", {
        params: { state: state },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};
const mutations = {
  setUserInfo(state, data) {
    state.user = data.data;
  },
  resetUserInfo(state) {
    // state.user = {
    //   id: null,
    //   username: null,
    //   email: null,
    //   plan_preferences: {},
    // };
    state.authHeaders = null;
    localStorage.removeItem("authHeaders");
    // axiosInstance.defaults.headers.common["Authorization"] = null;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
