import axiosInstance from "../../api/axiosInstance";

const getDefaultState = () => {
  return {
    payment: {},
    transaction: {},
  };
};

const paymentManager = {
  state: () => getDefaultState(),
  mutations: {
    clearPayment(state) {
      state = {
        payment: {},
      };
    },
    setReceiptInfo(state, data) {
      state.payment = data;
    },
    setTransaction(state, data) {
      state.transaction = data;
    },
  },
  getters: {
    payment(state) {
      return state.payment;
    },
    transaction(state) {
      return state.transaction;
    },
  },
  actions: {
    capturePayment({ commit }, payment) {
      return axiosInstance
        .post("/payments", { payment: { ...payment } })
        .then((res) => {
          commit("setReceiptInfo", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    showPayment({ commit }, id) {
      return axiosInstance
        .get(`/payments/${id}`)
        .then((res) => {
          commit("setReceiptInfo", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    showTransaction({ commit }, id) {
      return axiosInstance
        .get(`/transactions/${id}`)
        .then((res) => {
          commit("setTransaction", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    getPaymentToken() {
      return axiosInstance
        .get("/payment_token")
        .then((r) => r)
        .catch((e) => e.response);
    },
    loadDiscountCode({}, { discountCode, planId, profileCount }) {
      return axiosInstance
        .get("/discount_code", {
          params: {
            discount_code: discountCode,
            plan_id: planId,
            student_limit: profileCount,
          },
        })
        .then((res) => res)
        .catch((e) => e.response);
    },
  },
};

export default paymentManager;
