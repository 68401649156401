import axiosInstance from "../../api/axiosInstance";

const getDefaultState = () => {
  return {
    surveyOptions: {},
  };
};

const nonAuthenticatedModule = {
  state: () => getDefaultState(),
  mutations: {
    setSurveyOptions: (state, options) => (state.surveyOptions = options),
    setJobTypeOptions: (state, options) => (state.jobTypeOptions = options),
  },
  getters: {
    surveyOptions: (state) => state.surveyOptions,
    jobTypeOptions: (state) => state.jobTypeOptions,
  },
  actions: {
    loadSurveyOptions({ commit }) {
      return axiosInstance
        .get("/survey_options")
        .then((res) => {
          commit("setSurveyOptions", res.data.survey_options);
          commit("setJobTypeOptions", res.data.job_type_options);
          return res;
        })
        .catch((e) => e.response);
    },
  },
};

export default nonAuthenticatedModule;
