import { createApp } from "vue";
import { createGtm } from "vue-gtm";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { setRootUrl } from "./api/axiosInstance";
import Rollbar from "rollbar";
import mixpanel from "./api/mixpanelInstance";

const rollbar = new Rollbar({
  accessToken: process.env.POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: false,
  captureUnhandledRejections: false,
  payload: {
    environment:
      process.env.STAGING == "true" ? "staging" : process.env.NODE_ENV,
  },
});

import "./assets/style/main.scss";
import "bootstrap";

import BackButton from "./components/BackButton.vue";
import PrimaryButton from "./components/PrimaryButton.vue";

const utilMethods = {
  methods: {
    formatMoney(amount) {
      return parseFloat(amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
};

document.addEventListener("DOMContentLoaded", () => {
  const rootURL = document.querySelector("meta[name='apiurl']").content;
  setRootUrl(rootURL);
  const app = createApp(App);
  app.config.errorHandler = (err, vm, info) => {
    rollbar.error(err);
    throw err;
  };
  app.config.globalProperties.$rollbar = rollbar;
  app.config.globalProperties.$mixpanel = mixpanel;
  app.config.globalProperties.$toggleSidebar = () => {
    document.querySelector(".sidebar")?.classList.toggle("show");
    document.querySelectorAll(".sidebar .toggle-wrapper button rect").forEach((rect) => {
      rect.classList.toggle("active");
    });
  };
  app
    .use(
      createGtm({
        id: process.env.GA_ID,
        queryParams: {
          gtm_auth: process.env.GA_AUTH,
          gtm_preview: process.env.GA_ENV,
          gtm_cookies_win: "x",
        },
        defer: true,
        compatibility: true,
        nonce: Math.random().toString(36).slice(2),
        enabled: true,
        debug: process.env.NODE_ENV === "development",
        loadScript: true,
        vueRouter: router,
        ignoredViews: [""],
        trackOnNextTick: false,
      })
    )
    .use(store)
    .use(router)
    .mixin(utilMethods)
    .component("back-button", BackButton)
    .component("primary-button", PrimaryButton)
    .mount("#app");
});
