import axiosInstance from "../../api/axiosInstance";

const getDefaultState = () => {
  return {
    subscriptions: [],
  };
};

const subscriptionModule = {
  state: () => getDefaultState(),
  mutations: {
    setSubscriptions(state, data) {
      state.subscriptions = data;
    },
  },
  getters: {
    subscriptions: (state) => state.subscriptions,
  },
  actions: {
    loadSubscriptionPlans({ commit }, role) {
      return axiosInstance
        .get("/subscriptions", { params: { role: role } })
        .then((res) => {
          commit("setSubscriptions", res.data);
          return res;
        });
    },
    selectPlan({ commit }, { plan, student_limit }) {
      return axiosInstance
        .post("/user_subscriptions", {
          subscription_id: plan.id,
          student_limit: student_limit,
          billing_cycle: plan.billing_cycle,
        })
        .then((res) => {
          return res;
        })
        .catch((e) => e.response);
    },
    cancelSubscription({}, cancelReasons) {
      return axiosInstance
        .delete("/user_subscriptions", {
          params: { cancel_reasons: cancelReasons },
        })
        .then((res) => {
          return res;
        })
        .catch((err) => err.response);
    },
    updatePaymentMethod({}, payload) {
      return axiosInstance
        .put("/user_subscriptions/update_payment_method", payload)
        .then((res) => {
          return res;
        })
        .catch((e) => e.response);
    },
    updateSubscription({}, payload) {
      return axiosInstance
        .put("/user_subscriptions/change_subscription", payload)
        .then((res) => {
          return res;
        })
        .catch((e) => e.response);
    },
    calculateProration({}, payload) {
      return axiosInstance
        .post("/user_subscriptions/calculate_change", payload)
        .then((res) => {
          return res;
        })
        .catch((e) => e.response);
    },
  },
};

export default subscriptionModule;
