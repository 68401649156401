import { createStore } from "vuex";
import sessionManager from "./modules/sessionManager";
import paymentManager from "./modules/paymentManager";
import subscriptionModule from "./modules/subscriptionModule";
import nonAuthenticatedModule from "./modules/nonAuthenticated";
import axiosInstance from "../api/axiosInstance";

const getDefaultState = () => {
  return {
    blogPosts: [],
    cmsCategories: [],
    contents: [],
    states: {},
    countries: {},
    courtesyTitles: {},
    institutionTypes: [],
    favoriteList: [],
    transactionHistory: [],
    cancelSurveyOptions: [],
  };
};

export default createStore({
  modules: {
    sessionManager: sessionManager,
    paymentManager: paymentManager,
    subscriptionModule: subscriptionModule,
    nonAuthenticatedModule: nonAuthenticatedModule,
  },
  state: getDefaultState(),
  getters: {
    blogPosts: (state) => state.blogPosts,
    states: (state) => state.states,
    countries: (state) => state.countries,
    courtesyTitles: (state) => state.courtesyTitles,
    institutionTypes: (state) => state.institutionTypes,
    cmsCategories: (state) => state.cmsCategories,
    contents: (state) => state.contents,
    favoriteList: (state) => state.favoriteList,
    episodes: (state) => state.episodes,
    episode: (state) => state.episode,
    transactionHistory: (state) => state.transactionHistory,
    cancelSurveyOptions: (state) => state.cancelSurveyOptions,
    videoDemoURL: () =>
      "https://app.pitch.com/app/presentation/e8308fd4-137a-4eb0-8c11-33727e0987c7/038df719-d2ee-4773-81df-3498ca1de041/844240a8-789e-4d02-9118-f7e894bdf111",
  },
  mutations: {
    setCancelSurveyOptions(state, data) {
      state.cancelSurveyOptions = data;
    },
    clearState(state) {
      state = getDefaultState();
    },
    setTransactionHistory(state, data) {
      state.transactionHistory = data;
    },
    setBlogPosts(state, data) {
      state.blogPosts = data;
    },
    setStates(state, data) {
      state.states = data;
    },
    setAccountSettings(state, data) {
      state.states = data.states;
      state.countries = data.countries;
      state.courtesyTitles = data.courtesy_titles;
      state.institutionTypes = data.institution_types;
    },
    setCMS(state, data) {
      state.cmsCategories = data.categories;
    },
    setContent(state, data) {
      state.contents = data;
    },
    updateFavorites(state, data) {
      state.favoriteList = data;
    },
    setEpisodeInfo(state, data) {
      state.episodes = data;
    },
    setEpisode(state, data) {
      state.episode = data;
    },
  },
  actions: {
    loadTransactions({ commit }) {
      return axiosInstance
        .get("/load_transactions")
        .then((res) => {
          commit("setTransactionHistory", res.data);
          return res;
        })
        .catch((err) => err.response);
    },
    loadBlogPosts({ commit }) {
      return axiosInstance
        .get("/load_blog_posts")
        .then((response) => commit("setBlogPosts", response.data))
        .catch((error) => error.response);
    },
    loadAccountSettings({ commit }) {
      return axiosInstance
        .get("/account_settings")
        .then((response) => commit("setAccountSettings", response.data))
        .catch((error) => error.response);
    },
    loadStates({ commit }, country) {
      return axiosInstance
        .get("/load_states", {
          params: {
            country: country,
          },
        })
        .then((response) => commit("setStates", response.data))
        .catch((error) => error.response);
    },
    saveAccountSettings({}, user) {
      return axiosInstance
        .patch(`users/${user.id}`, user)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    loadCMSPage({ commit }, page) {
      return axiosInstance
        .get("/cms/load_page", { params: { page: page } })
        .then((response) => {
          commit("setCMS", response.data);
          return response;
        })
        .catch((error) => error.response);
    },
    loadContents({ commit }, categoryId) {
      return axiosInstance
        .get(`/cms/load_contents/${categoryId}`)
        .then((res) => {
          commit("setContent", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    loadFavorites({ commit }) {
      return axiosInstance
        .get("/cms/load_favorites")
        .then((res) => {
          commit("setContent", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    saveToFavorites({ commit }, contentId) {
      return axiosInstance
        .post("/cms/save_favorite", { content_id: contentId })
        .then((response) => {
          commit("updateFavorites", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    submitContactForm({}, data) {
      return axiosInstance
        .post("/users/contact_form", data)
        .then((res) => res)
        .catch((e) => e.response);
    },
    loadEpisodes({ commit }) {
      return axiosInstance
        .get("/episodes")
        .then((res) => {
          commit("setEpisodeInfo", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    loadEpisode({ commit }, id) {
      return axiosInstance
        .get(`/episodes/${id}`)
        .then((res) => {
          commit("setEpisode", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
    loadCancelSurveyOptions({ commit }) {
      return axiosInstance
        .get("/cancel_survey_options")
        .then((res) => {
          commit("setCancelSurveyOptions", res.data);
          return res;
        })
        .catch((e) => e.response);
    },
  },
});
