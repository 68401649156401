<template>
  <transition name="fade" mode="out-in">
    <div v-if="show" :class="`alert alert-${style} text-center`">
      <span v-html="message"></span>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        v-on:click="show = false"
      ></button>
    </div>
  </transition>
</template>
<script>
export default {
  name: "FlashMessage",
  data() {
    return {
      message: "",
      style: "danger",
      show: false,
      timeoutId: null,
    };
  },
  props: {
    delay: Number,
  },
  methods: {
    dismiss() {
      this.show = false;
      clearTimeout(this.timeoutId);
    },
    setMessage({ message, style, delay }) {
      this.dismiss();
      (this.message = message), (this.style = style || "danger");
      this.show = true;
      this.timeoutId = setTimeout(this.dismiss, this.delay || delay || 5000);
    },
  },
};
</script>
<style lang="scss" scoped>
div.alert {
  white-space: nowrap;
  position: fixed;
  z-index: 1000000;
  bottom: 50;
  right: 50;
  min-width: 300px;
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
  button {
    margin-left: auto;
    position: absolute;
    top: 10;
    right: 10;
    padding: 0;
  }
}
</style>
