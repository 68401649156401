<template>
  <div class="wrapper">
    <div class="loader-overlay" :class="{ blocking: isLoading || disable }">
      <div class="spinner-wrapper">
        <span
          v-if="isLoading"
          :class="`spinner-border spinner-border-${spinnerSize}`"
        ></span>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    size: String,
    disable: Boolean,
    delay: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    spinnerSize() {
      return this.size || "sm";
    },
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    loaded() {
      setTimeout(() => {
        this.isLoading = false;
      }, this.delay);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background: transparent;
  position: relative;
  display: block;
  .loader-overlay {
    &.blocking {
      cursor: not-allowed;
      overflow: hidden;
      pointer-events: all;
      background-color: rgba(0, 0, 0, 0.55);
    }
    pointer-events: none;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    .spinner-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      span.spinner-border {
        margin: auto;
        color: white;
      }
    }
  }
}
</style>
