import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

function userSubscribed() {
  const user = store.getters["user"];
  return user && user.subscribed && user.active_subscriber;
}

function userCancelled() {
  const user = store.getters["user"];
  return user && user.cancelled_at && user.cancelled_at.length > 0;
}

function accountManaged() {
  const user = store.getters["user"];
  return user && user.account_managed;
}

const routes = [
  {
    path: "/hub",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/Login.vue"),
        beforeEnter: (to, from, next) => {
          store.commit("clearState");
          store.dispatch("loginUserWithToken").then((response) => {
            if (response.status < 300) {
              next({ name: "hub" });
            } else {
              next();
            }
          });
        },
      },
      {
        path: "signup",
        name: "signup",
        component: () => import("../views/Signup.vue"),
        beforeEnter: (to, from, next) => {
          store.commit("clearState");
          store.dispatch("loginUserWithToken").then((response) => {
            if (response.status < 300) {
              next({ name: "hub" });
            } else {
              next();
            }
          });
        },
      },
      {
        path: "",
        name: "hub",
        component: () => import("../views/Hub.vue"),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          store.dispatch("loadEpisodes").then((response) => {
            if (response.status < 300) {
              next();
            } else {
              next(false); // TODO: show an error
            }
          });
        },
      },
      {
        path: "account",
        name: "account",
        component: () => import("../views/Account.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "details",
            name: "accountDetails",
            component: () => import("../components/account/ProfileDetails.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "password",
            name: "accountPassword",
            component: () => import("../components/account/Password.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "billing",
            name: "accountBilling",
            component: () => import("../components/account/Billing.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "",
            name: "accountHome",
            component: () => import("../components/account/Home.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "payment",
            name: "accountPayment",
            component: () => import("../components/account/Payment.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "setup",
        name: "setup",
        component: () => import("../views/Setup.vue"),
        redirect: { name: "setupAccount" },
        beforeEnter: (to, from, next) => {
          // block managed users from accessing setup screens
          if (accountManaged()) {
            next({ name: "hub" });
          } else {
            next();
          }
        },
        children: [
          {
            path: "select-role",
            name: "selectRole",
            component: () => import("../components/setup/SelectRole.vue"),
            meta: {
              requiresAuth: true,
              step: 1,
            },
          },
          {
            path: "plan/professional",
            name: "professionalPlan",
            component: () =>
              import("../components/setup/plan/ProfessionalPlan.vue"),
            meta: {
              requiresAuth: true,
              step: 2,
            },
            beforeEnter: (to, from, next) => {
              if (userSubscribed()) {
                next({ name: "editProfessionalPlan" });
              } else {
                next();
              }
            },
          },
          {
            path: "plan/guardian",
            name: "guardianPlan",
            component: () =>
              import("../components/setup/plan/GuardianPlan.vue"),
            meta: {
              requiresAuth: true,
              step: 2,
            },
            beforeEnter: (to, from, next) => {
              if (userSubscribed()) {
                next({ name: "editGuardianPlan" });
              } else {
                next();
              }
            },
          },
          {
            path: "plan/guardian/edit",
            name: "editGuardianPlan",
            component: () =>
              import("../components/setup/plan/EditGuardianPlan.vue"),
            meta: {
              requiresAuth: true,
              showCancel: true,
            },
            beforeEnter: (to, from, next) => {
              if (userCancelled() || !userSubscribed()) {
                next({ name: "guardianPlan" });
              } else {
                next();
              }
            },
          },
          {
            path: "plan/professional/edit",
            name: "editProfessionalPlan",
            component: () =>
              import("../components/setup/plan/EditProfessionalPlan.vue"),
            meta: {
              requiresAuth: true,
              showCancel: true,
            },
            beforeEnter: (to, from, next) => {
              if (userCancelled() || !userSubscribed()) {
                next({ name: "professionalPlan" });
              } else {
                next();
              }
            },
          },
          {
            path: "contact-step",
            name: "contactStep",
            component: () => import("../components/setup/Contact.vue"),
            meta: {
              requiresAuth: true,
              step: 2,
            },
          },
          {
            path: "contact",
            name: "contact",
            component: () => import("../components/setup/Contact.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "transaction/:transactionId",
            name: "transaction",
            component: () => import("../components/setup/Complete.vue"),
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "complete/:transactionId",
            name: "setupComplete",
            props: true,
            component: () => import("../components/setup/Complete.vue"),
            meta: {
              requiresAuth: true,
              step: 3,
            },
          },
          {
            path: "cancel",
            name: "cancelSubscription",
            component: () => import("../components/setup/Cancel.vue"),
            meta: {
              requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
              if (userCancelled()) {
                next({ name: "accountHome" });
              } else {
                next();
              }
            },
          },
        ],
      },
      {
        path: "content",
        name: "content",
        component: () => import("../views/Content.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "helpful-links-sites",
            name: "contentLinks",
            component: () => import("../components/content/Links.vue"),
            props: { page: "links" },
            meta: {
              requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
              store.commit("setContent", []);
              next();
            },
          },
          {
            path: "downloads-and-printables",
            name: "downloads",
            component: () => import("../components/content/Links.vue"),
            props: { page: "downloads" },
            meta: {
              requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
              store.commit("setContent", []);
              next();
            },
          },
        ],
      },
      {
        path: "episodes/:id",
        name: "episode",
        component: () => import("../views/Episodes.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          store.dispatch("loadEpisode", to.params.id).then((res) => {
            if (res.status < 300) {
              next();
            } else {
              // TODO: show an error
              next(false);
            }
          });
        },
      },
      {
        path: "/:notFound(.*)",
        name: "notFound",
        redirect: { name: "hub" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch("loginUserWithToken").then((response) => {
      if (response.status < 300) {
        next();
      } else {
        next({ name: "login" });
      }
    });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // Update Timezone when changed
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const user = store.getters["user"];
  if (user && user.id && user.timezone != tz) {
    store.dispatch("saveAccountSettings", { timezone: tz });
  }
});

export default router;
