<template>
  <loader
    ref="loader"
    class="loader"
    :class="{ enabled: !disabled }"
    :disable="disabled"
  >
    <template #content>
      <button
        :id="btnId"
        v-on:click.prevent="clicked"
        class="primary-button"
        :class="{ wrap: textWrap }"
      >
        <slot v-if="!btnText" name="btnText"></slot>
        {{ btnText }}
      </button>
    </template>
  </loader>
</template>
<script>
import Loader from "./Loader.vue";
export default {
  name: "PrimaryButton",
  components: { Loader },
  emits: ["clicked"],
  props: {
    btnId: String,
    btnText: String,
    textWrap: Boolean,
    loader: Boolean,
    disabled: Boolean,
  },
  methods: {
    clicked(event) {
      if (this.loader) this.$refs.loader.loading();
      this.$emit("clicked", { btn: this, event: event });
    },
    complete() {
      this.$refs.loader.loaded();
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  border-radius: 50px;
  overflow: hidden;
  border: 3px solid transparent;
  min-width: 282px;
  &.enabled:hover {
    background: linear-gradient(rgba(31, 34, 67, 1), rgba(31, 34, 67, 1))
        padding-box,
      linear-gradient(170.64deg, #cb6e2b 9.26%, #fbcc08 93.57%) border-box;
    border-radius: 50px;
    border: 3px solid transparent;
  }

  button.primary-button {
    text-transform: uppercase;
    text-align: center;
    font-family: "Montserrat Alternates";
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    white-space: nowrap;
    &.wrap {
      padding: 0 60px;
      white-space: normal;
    }
    color: white;
    cursor: pointer;
    background-color: transparent;
    background-image: url("~primary-button-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    width: 100%;
    height: 50px;
    border: none;
  }
}
</style>
