<template>
  <button
    v-on:mouseover="hover(true)"
    v-on:mouseleave="hover(false)"
    v-on:click.prevent="goBack"
    class="back-button"
  >
    <div class="d-flex flex-row align-items-center">
      <chevron-right v-if="icon" class="ms-2 me-auto" :color="chevronColor" />
      <span v-if="!$slots.content" class="mx-auto px-2">{{ buttonText }}</span>
      <slot name="content" />
    </div>
  </button>
</template>
<script>
import ChevronRight from "./icons/ChevronRight.vue";

export default {
  name: "BackButton",
  components: { ChevronRight },
  data() {
    return {
      chevronColor: "#8d97df",
    };
  },
  props: {
    text: String,
    backTo: String,
    handleClick: {
      type: Function,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonText() {
      return this.text || "Back to hub";
    },
    backToName() {
      return this.backTo || "hub";
    },
  },
  methods: {
    goBack() {
      if (this.handleClick) {
        this.handleClick();
        return;
      }
      this.$router.push({ name: this.backToName });
    },
    hover(hovered) {
      if (hovered) {
        this.chevronColor = "#13257c";
      } else {
        this.chevronColor = "#8d97df";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.back-button {
  background-color: transparent;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px rgba(19, 37, 124, 0.15);
  border-radius: 35px;
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #8d97df;
  text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.75);
  border: 2px solid #8d97df;
  height: 50px;
  text-align: center;
  background: radial-gradient(rgb(13, 16, 29, 0.2), rgba(16, 19, 50, 0.3));
  padding: 0 1.5rem;
  white-space: nowrap;
  svg {
    stroke: #8d97df;
    fill: #8d97df;
  }
  &:hover {
    padding: 0 1.4rem;
    color: #13257c;
    background: linear-gradient(180deg, #9eb4ff 0%, #cc8bff 100%);
    text-shadow: none;
    font-weight: 700;
    svg {
      stroke: #13257c;
      fill: #13257c;
    }
  }
}
</style>
