<template>
  <flash-message ref="flash" />
  <router-view />
</template>
<script>
import FlashMessage from "./components/FlashMessage.vue";
export default {
  name: "App",
  components: {
    FlashMessage,
  },
  mounted() {
    const flashMessage = document.querySelector("#app").dataset.flashMessage;
    if (flashMessage) {
      this.$refs.flash.setMessage({
        message: flashMessage,
        style: "warning",
      });
    }
  },
};
</script>
