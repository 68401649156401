import axios from "axios";

const axiosInstance = axios.create({
  timeout: 15000, // ms
  headers: {
    common: {
      "content-type": "application/json",
    },
  },
});

export function setRootUrl(rootURL) {
  axiosInstance.defaults.baseURL = rootURL;
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers["access-token"]) {
      localStorage.setItem(
        "authHeaders",
        JSON.stringify({
          "access-token": response.headers["access-token"],
          "token-type": "Bearer",
          client: response.headers.client,
          expiry: response.headers.expiry,
          uid: response.headers.uid,
        })
      );
    }
    return response;
  },
  (error) => {
    if (error.response.headers["access-token"]) {
      const headers = error.response.headers;
      localStorage.setItem(
        "authHeaders",
        JSON.stringify({
          "access-token": headers["access-token"],
          "token-type": "Bearer",
          client: headers.client,
          expiry: headers.expiry,
          uid: headers.uid,
        })
      );
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    var authHeaders = localStorage.getItem("authHeaders");
    if (authHeaders) {
      authHeaders = JSON.parse(authHeaders);
      config.headers = { ...config.headers, ...authHeaders };
    }
    config.headers["X-CSRF-Token"] = document.querySelector(
      "meta[name='csrf-token']"
    ).content;
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
