<template>
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.01193"
      y1="8.3314"
      x2="8.70424"
      y2="1.87679"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="1.40883"
      y1="8.74686"
      x2="9.10114"
      y2="15.2015"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronRight",
  props: {
    color: String
  }
};
</script>

<style></style>
